import Link from 'next/link';
import { usePathname } from 'next/navigation';

import HamburgerIcon from '@/shared/components/icons/hamburger-icon';
import { ThemeToggle } from '@/shared/components/theme-toggle';
import { Button } from '@/shared/components/ui/button';
import { Separator } from '@/shared/components/ui/separator';
import { Sheet, SheetClose, SheetContent, SheetTrigger } from '@/shared/components/ui/sheet';
import { cn } from '@/shared/lib/cn';

export const Sidebar = () => {
  const pathname = usePathname();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="secondary" size="icon-lg" aria-label="Раскрыть навигационное меню">
          <HamburgerIcon />
        </Button>
      </SheetTrigger>

      <SheetContent className="flex flex-col justify-between pl-8">
        <nav className="flex flex-col place-items-start py-10">
          <div className="flex w-full flex-col space-y-3">
            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/' })}
                href="/"
                aria-label="Перейти на главную"
              >
                Главная
              </Link>
            </SheetClose>

            <Separator className="mb-4 mt-2 w-full" />

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/individual-lessons' })}
                href="/individual-lessons"
                aria-label="Перейти на страницу Индивидуальные занятия"
              >
                Индивидуальные занятия
              </Link>
            </SheetClose>

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/pair-lessons' })}
                href="/pair-lessons"
                aria-label="Перейти на страницу Парные занятия"
              >
                Парные занятия
              </Link>
            </SheetClose>

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/group-lessons' })}
                href="/group-lessons"
                aria-label="Перейти на страницу Групповые занятия"
              >
                Групповые занятия
              </Link>
            </SheetClose>

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/speaking-clubs' })}
                href="/speaking-clubs"
                aria-label="Перейти на страницу Разговорный клуб"
              >
                Разговорный клуб
              </Link>
            </SheetClose>

            <Separator className="mb-4 mt-2 w-full" />

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/for-companies' })}
                href="/for-companies"
                aria-label="Перейти на страницу Для компаний"
              >
                Для компаний
              </Link>
            </SheetClose>

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/cases' })}
                href="/cases"
                aria-label="Перейти на страницу Кейсы"
              >
                Кейсы
              </Link>
            </SheetClose>

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/team' })}
                href="/team"
                aria-label="Перейти на страницу Команда"
              >
                Команда
              </Link>
            </SheetClose>

            <Separator className="mb-4 mt-2 w-full" />

            <SheetClose asChild>
              <Link
                className={cn({ 'text-brand-accent-normal': pathname === '/payment' })}
                href="/payment"
                aria-label="Перейти на страницу Оплата"
              >
                Оплата
              </Link>
            </SheetClose>
          </div>
        </nav>

        <div>
          <Separator className="mb-4 mt-2 w-full" />
          <ThemeToggle />
        </div>
      </SheetContent>
    </Sheet>
  );
};
